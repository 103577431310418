import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

const Experience = ({ experiences }) => {
  const [activeExperience, setActiveExperience] = useState({
    ...experiences[0],
    index: 0,
  });

  return (
    <div className="is-hidden-touch">
      <div className="is-flex">
        <div className="experience-card-container">
          {experiences?.map((experience, index) => (
            <button
              className={classNames("experience-card-header", {
                "is-active": activeExperience?.index === index,
              })}
              onClick={() => setActiveExperience({ ...experience, index })}
            >
              <p className="experience-title">{experience?.data?.name}</p>
              <p className="experience-date">
                {moment(experience?.data?.startDate).format("MMMM YYYY")} -{" "}
                {experience?.data?.isCurrentJob
                  ? "Present"
                  : moment(experience?.data?.endDate).format("MMMM YYYY")}
              </p>
            </button>
          ))}
        </div>
        <div className="experience">
          <h1 className="title">{activeExperience?.data?.name}</h1>
          <p className="company">
            <span>
              <FontAwesomeIcon icon={faBuilding} />{" "}
            </span>
            {activeExperience?.data?.company}
          </p>
          <p className="timeline">
            <span>
              <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            </span>
            {moment(activeExperience?.data?.startDate).format("MMMM YYYY")} -{" "}
            {activeExperience?.data?.isCurrentJob
              ? "Present"
              : moment(activeExperience?.data?.endDate).format("MMMM YYYY")}
          </p>
          <div className="description">
            {activeExperience?.data?.description}
          </div>
          <div className="responsibilities">
            <h1>Key Responsibilities</h1>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html:
                  activeExperience?.data?.responsibilities?.childMarkdownRemark?.html?.replace(
                    /\\n/g,
                    ""
                  ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
