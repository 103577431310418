import { graphql, useStaticQuery } from "gatsby";

export const useExperiences = () => {
  let experiencesQuery = graphql`
    query experiencesQuery {
      allAirtableExperiences(sort: { fields: data___Order, order: ASC }) {
        nodes {
          data {
            name: Name
            isCurrentJob: Currently_Working_Here
            company: Company
            description: Description
            endDate: End_Date
            startDate: Start_Date
            responsibilities: Responsibilities {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `;

  return useStaticQuery(experiencesQuery);
};
