import React from "react";

import Layout from "layouts/Layout";
import Experiences from "../components/Experiences";

const ExperiencePage = () => {
  return (
    <Layout seoTitle="Experiences">
      <Experiences />
    </Layout>
  );
};

export default ExperiencePage;
