import React, { useState, Fragment } from "react";
import classNames from "classnames";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

const ExperienceMobile = ({ experiences }) => {
  const [activeExperience, setActiveExperience] = useState({
    ...experiences[0],
    index: 0,
  });

  return (
    <div className="is-hidden-desktop">
      {experiences?.map((experience, index) => (
        <Fragment>
          <button
            className={classNames("experience-card-header-touch", {
              "is-active": activeExperience?.index === index,
            })}
            onClick={() => setActiveExperience({ ...experience, index })}
          >
            <p className="experience-title">{experience?.data?.name}</p>
            <p className="experience-date">
              {moment(experience?.data?.startDate).format("MMMM YYYY")} -{" "}
              {experience?.data?.isCurrentJob
                ? "Present"
                : moment(experience?.data?.endDate).format("MMMM YYYY")}
            </p>
          </button>
          <div
            className={classNames("experience-touch-container", {
              "is-active": activeExperience?.index === index,
            })}
          >
            <div className="experience-touch">
              <h1 className="title">{experience?.data?.name}</h1>
              <p className="company">
                <span>
                  <FontAwesomeIcon icon={faBuilding} />{" "}
                </span>
                {experience?.data?.company}
              </p>
              <p className="timeline">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                </span>
                {moment(experience?.data?.startDate).format("MMMM YYYY")} -{" "}
                {experience?.data?.isCurrentJob
                  ? "Present"
                  : moment(experience?.data?.endDate).format("MMMM YYYY")}
              </p>
              <div className="description">{experience?.data?.description}</div>
              <div className="responsibilities">
                <h1>Key Responsibilities</h1>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html:
                      experience?.data?.responsibilities?.childMarkdownRemark?.html?.replace(
                        /\\n/g,
                        ""
                      ),
                  }}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ))}
      <div className="is-flex">
        <div></div>
      </div>
    </div>
  );
};

export default ExperienceMobile;
