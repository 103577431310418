import React, { useEffect, useContext } from "react";
import { gsap } from "gsap";

import Experience from "./Components/Experience";
import ExperienceMobile from "./Components/ExperienceMobile";

import "./style/experiences.scss";
import { AppContext } from "../../context/AppContext";
import { useExperiences } from "./hooks/useExperiences";

const Experiences = () => {
  const { state } = useContext(AppContext);

  let experiences = useExperiences();
  experiences = experiences?.allAirtableExperiences?.nodes;

  useEffect(() => {
    let experienceCardTimeline = gsap.timeline();
    let pageContentTimeLine = gsap.timeline();

    experienceCardTimeline.from(".experience-card-header", {
      duration: 0.5,
      delay: state?.isInitialLoad ? 0.7 : 1.3,
      stagger: 0.2,
      opacity: 0,
      y: 20,
    });

    pageContentTimeLine.from(".experience", {
      duration: 0.5,
      delay: state?.isInitialLoad ? 0.7 : 1.3,
      opacity: 0,
      y: 20,
    });

    //eslint-disable-next-line
  }, []);

  return (
    <div className="experiences-page-container">
      <Experience experiences={experiences} />
      <ExperienceMobile experiences={experiences} />
    </div>
  );
};

export default Experiences;
